import { lightWindowManager } from "./lightwindowhelper";

var contactBtn = document.querySelector('.js-siteheader-contact');

if (contactBtn) {
    contactBtn.addEventListener('click', function (event) {
        event.preventDefault();
        lightWindowManager.open({ name: 'contact' });
    });
}

var hideAccountMenu = function (e, forced) {
    var accountMenuEl = $('.js-accountmenu').first();
    if (forced === true || (!$.contains(accountMenuEl[0], e.target) && e.target != accountMenuEl[0])) {
        if (typeof e !== "undefined") {
            e.preventDefault();
        }
        $('body').off('click', hideAccountMenu);
        accountMenuEl.hide();
    }
};

var showAccountMenu = function (e) {
    e.preventDefault();
    var accountMenuEl = $('.js-accountmenu').first();
    accountMenuEl.show();
    $('body').on('click', hideAccountMenu);
};

$('body').on('click', '.js-accountmenu-closebtn', function (e) { hideAccountMenu(e, true) });
$('body').on('click', '.js-siteheader-accountbtn', showAccountMenu);
