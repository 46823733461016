interface ILightWindowOptions {
    data?: string;
    name?: string;
    context?: string | any;
    shouldCache?: boolean;
    isUndismissable?: boolean;
    childLightWindow?: any;
    filePath?: string;
    initfunction?: () => void;
    callbackonclose?: () => void;
    callback?: () => void;
}

type ILightWindow = ((
    this: ILightWindowState,
    parentLightWindow?: ILightWindow
) => void) &
    ILightWindowState;

interface ILightWindowState {
    validLightWindows?: any;
    preloaderMarkup?: string;
    lwContainerTemplate?: string;
    alertTemplate?: (message: string, title: string, footer: string) => string;
    parentLightWindow?: ILightWindow;
    parentElement?: any;
    cover?: any;
    childLightWindow?: ILightWindow;
    openedLightWindows?: string[];
    currentLightWindow?: any;
    coverNumber?: number;
    fadeLength?: number;
    preloaderElement?: any;
    cacheEl?: any;
    shouldCache?: boolean;
    lastFocus?: Element;
    scrollbarWidth?: number;
    isFixedElementFixApplied?: boolean;
    /**
     * These functions are called, but not sure where they're defined
     */

    hasClass?: (className: string) => boolean;
    getTopCover?: () => any;
    find?: (className: string) => any;

    // prototypes
    init?: () => void;
    open?: (options: ILightWindowOptions) => void;
    openEl?: (e: any) => void;
    display?: (name: any, args: ILightWindowOptions) => void;
    close?: (name, shouldremove?: boolean, onComplete?: () => void) => void;
    getTopLightWindow?: () => ILightWindow;
    showAlert?: (args: {}) => void;
    verticallyCenter?: (e: any) => void;
}

declare global {
    interface Window {
        dataLayer: any;
        lightWindowManager: ILightWindow;
    }

    interface TElement {
        childLightWindow?: ILightWindow;
    }
}

export var lightWindowManager: ILightWindow;

(function ($: JQueryStatic) {
    const LightWindow: ILightWindow = function LightWindow(
        parentLightWindow?: ILightWindow
    ): void {
        this.validLightWindows = parentLightWindow
            ? parentLightWindow.validLightWindows
            : ["contact", "security", "legal", "tvad"];
        this.preloaderMarkup =
            '<div class="lightwindow-preloader" style="display:none;"><img src="/images/loader.gif" /></div>';
        this.lwContainerTemplate =
            '<div class="lightwindow-container lightwindow lwindow js-lightwindow lightwindow--closed"></div>';
        this.alertTemplate = function (message, title, footer) {
            let alertwindow = "";
            title = title || "";
            const hastitle =
                typeof title !== "undefined" && title.length > 0 ? "" : " is-hidden";
            footer =
                footer ||
                '<div class="alert-footer"><button type="button" class="btn-primary btn-alert js-lightwindow-close js-alert-close js-lightwindow-enterkey">Ok</button></div></div>';
            alertwindow +=
                '<div class="lightwindow-content alert-content" role="alertdialog"><div class="alert-content-container"><span class="icon alert-icon"></span><h1 class="alert-header js-alert-header' +
                hastitle +
                '">' +
                title +
                "</h1>";
            alertwindow +=
                '<div class="alert-message js-alert-message">' + message + "</div></div>";
            alertwindow += footer;
            return alertwindow;
        };
        this.parentLightWindow = parentLightWindow;
        this.parentElement = parentLightWindow ? parentLightWindow.cover : $("body");
        this.cover = undefined;
        this.childLightWindow = undefined;
        this.openedLightWindows = [];
        this.currentLightWindow = undefined;
        this.coverNumber = parentLightWindow ? parentLightWindow.coverNumber + 1 : 0;
        this.fadeLength = 150;
        this.preloaderElement = undefined;
        this.cacheEl = undefined;
        this.shouldCache = undefined;
        this.lastFocus = document.activeElement;
        this.scrollbarWidth = 0;
        this.isFixedElementFixApplied = false;
    };

    LightWindow.prototype.init = function (): void {
        const that = this;

        if (!that.parentLightWindow && $(".js-lightwindowcache").length === 0) {
            $("body").append('<div class="js-lightwindowcache"></div>');
        }
        that.cacheEl = $(".js-lightwindowcache");

        //preload error icon incase of network issues
        new Image().src = "/images/svg/icons/icon-alert-error.svg?v=1";

        // window resize
        $(window).resize(function () {
            $(".lwindow:visible").each(function () {
                that.verticallyCenter($(this));
            });
        });

        // lightwindow keyboard functionality
        $(document).on("keydown", function (event) {
            const e = event;
            switch (e.which) {
                case 27: // esc
                    //trigger close window
                    if (lightWindowManager.currentLightWindow) {
                        // only close the highest lightwindow
                        const lw = lightWindowManager.getTopLightWindow();
                        const closebtn = $(lw)
                            .find(".js-lightwindow-close:visible")
                            .first();
                        if (closebtn.length > 0) {
                            closebtn.addClass("is-active");
                            closebtn.click();
                            setTimeout(function () {
                                //remove the class from the clicked element
                                closebtn.removeClass("is-active");
                            }, 150);
                        }
                    }
                    break;

                case 13: // enterkey, trigger click on js-lightwindow-enterkey (useful to dismiss alerts)
                    var lwToSubmit = lightWindowManager.getTopCover();
                    if (typeof lwToSubmit !== "undefined") {
                        const $lwel = lightWindowManager.getTopLightWindow();
                        if ($lwel && !$lwel.hasClass("is-lwtransitioning")) {
                            const lwenterkey = $lwel.find(".js-lightwindow-enterkey");
                            //check what element has focus, don't want to trigger click if we have tabbed to an item in the lightwindow
                            if (
                                $lwel[0] === document.activeElement ||
                                lwToSubmit.cover === document.activeElement ||
                                lwenterkey[0] === document.activeElement ||
                                document.body === document.activeElement
                            ) {
                                if (lwenterkey.length > 0) {
                                    //add class to show what element was clicked
                                    lwenterkey.addClass("is-active");
                                    lwenterkey.click();
                                    setTimeout(function () {
                                        //remove the class from the clicked element
                                        lwenterkey.removeClass("is-active");
                                    }, 150);
                                }
                            }
                        }
                    }
                    break;

                default:
                    return; // exit this handler for other keys
            }
        });

        //generic element click lightwindow open event listener
        $("body").on("click", ".js-open-lightwindow", function (e) {
            lightWindowManager.openEl(e);
        });
    };

    LightWindow.prototype.openEl = function (e): void {
        /* open a lightwindow from an element click */
        //convenience method, this should only be used for GET lightwindows not POST
        let args: any = {},
            name,
            filePath,
            shouldCache;
        name = $(e.currentTarget).attr("data-lw-name");
        filePath = $(e.currentTarget).attr("data-lw-path"); //if necessary params should be in query string
        shouldCache = $(e.currentTarget).attr("data-lw-domcache"); //should the window persist in the dom

        if (typeof name !== "undefined") {
            args.name = name;
        }
        if (typeof filePath !== "undefined") {
            args.filePath = filePath;
        }
        if (typeof shouldCache !== "undefined") {
            if (shouldCache === "false") {
                args.shouldCache = false;
            } else if (shouldCache === "true") {
                args.shouldCache = true;
            }
        }

        lightWindowManager.open(args);
    };

    LightWindow.prototype.open = function (options: ILightWindowOptions) {
        const that = this;
        const currentLw = that;
        that.lastFocus = document.activeElement;

        const defaults: ILightWindowOptions = {
            name: undefined,
            context: "top",
            shouldCache: true,
            isUndismissable: false
        };

        let context;

        // set options to defaults extended with options
        options = $.extend({}, defaults, options);

        // set the context
        context = options.context;

        function addChildLightWindow() {
            if (typeof lightWindowManager.currentLightWindow === "undefined") {
                // display lightwindow
                context.display(options.name, options);
            } else {
                if (context.childLightWindow) {
                    // close all children then add lightwindow
                    context.childLightWindow.close(
                        context.childLightWindow.currentLightWindow,
                        null,
                        function () {
                            // create new lightwindow in the given context
                            context.childLightWindow = new LightWindow(context);
                            context.childLightWindow.display(options.name, options);
                        }
                    );
                } else {
                    // create new lightwindow in the given context
                    context.childLightWindow = new LightWindow(context);
                    context.childLightWindow.display(options.name, options);
                }
            }
        }

        if (options.context === that) {
            if (typeof that.currentLightWindow === "undefined") {
                // display lightwindow
                context.display(options.name, options);
            } else {
                // close current and all children then display lightwindow
                context.close(context.currentLightWindow, null, function () {
                    context.display(options.name, options);
                });
            }
        } else if (options.context === "top") {
            context = lightWindowManager.getTopCover();
            addChildLightWindow();
        } else if (options.context === "asChild") {
            context = currentLw;
            addChildLightWindow();
        }
    };

    LightWindow.prototype.display = function (name, args: ILightWindowOptions): void {
        const that = this;
        args = args || {};
        args.data = args.data || "";

        args.filePath = args.filePath || "/lightwindow/" + name + ".aspx";

        const lightWindowId = "#" + this.lightWindowId(name);
        const lightWindowUrl = that.urlOverride(name) || args.filePath;
        //lightWindowUrl += (lightWindowUrl.indexOf('?') > 0 ? '&' : '?') + 'isPopup=1';
        that.currentLightWindow = name;
        that.lastFocus = document.activeElement;

        this.shouldCache =
            typeof args.shouldCache !== "undefined" ? args.shouldCache : true; //important we set this everytime, so we don't inherit a previous lightwindows property
        this.initfunction = args.initfunction ? args.initfunction : undefined; //important we set this everytime, whether it's a function or undefined, so we don't inherit a previous alerts function
        this.callbackonclose = args.callbackonclose ? args.callbackonclose : undefined; //important we set this everytime, whether it's a function or undefined, so we don't inherit a previous alerts function

        const $lwContainer = $("#" + this.lightWindowId(name));
        if ($lwContainer[0]) {
            that.showPopUp(name);
            if (typeof args.callback === "function") {
                args.callback();
            }
        } else {
            var fetchLightWindow = function () {
                $.ajax({
                    url: lightWindowUrl,
                    type: "POST",
                    data: args.data,
                    dataType: "html",
                    contentType: "application/x-www-form-urlencoded",
                    beforeSend: function (xhr) {
                        that.showCover(undefined, undefined, args.isUndismissable);
                        that.preloaderElement.show();
                    },
                    success: function (res, textStatus, xhr) {
                        if (that.cover) {
                            //check the user hasn't already closed the cover
                            that.preloaderElement.hide();
                            that.trackPopUpEventInGTM(lightWindowUrl);
                            that.showPopUp(name, res);
                            that.openedLightWindows.push(name);
                            if (typeof args.callback === "function") {
                                args.callback();
                            }
                        }
                    },
                    error: function (xhr, status, e) {
                        if (that.cover) {
                            //check the user hasn't already closed the cover
                            that.showAlert({
                                message:
                                    "Sorry, there was an error loading the requested content.",
                                title: undefined,
                                callbackonclose: undefined,
                                footer:
                                    '<div class="alert-footer is-multichoice"><button type="button" class="btn-primary js-alert-close js-lightwindow-enterkey js-alert-retry">Retry</button><button type="button" class="btn-secondary js-lightwindow-close js-alert-close js-alert-cancel">Cancel</button></div>',
                                initfunction: function ($lwContainer) {
                                    $lwContainer
                                        .find(".js-alert-cancel")
                                        .on("click", function () {
                                            that.close(name);
                                        });
                                    $lwContainer
                                        .find(".js-alert-retry")
                                        .on("click", function () {
                                            setTimeout(fetchLightWindow, 300);
                                        });
                                }
                            });
                        }
                    }
                });
            };
            fetchLightWindow();
        }
    };

    LightWindow.prototype.trackPopUpEventInGTM = function (lightWindowUrl: string) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ LightWindowUrl: lightWindowUrl });
        window.dataLayer.push({ event: "LightWindowOpenNew" });
    };

    LightWindow.prototype.fixedElementFix = function (isApplyFix: boolean): void {
        if (
            isApplyFix === true &&
            lightWindowManager.isFixedElementFixApplied === false
        ) {
            $(".js-fixed--marginleft").each(function (index, element) {
                const originalMarginLeft = parseFloat($(element).css("margin-left"));
                $(element).css(
                    "margin-left",
                    originalMarginLeft - lightWindowManager.scrollbarWidth / 2 + "px"
                );
            });
            $(".js-fixed--width100").each(function (index, element) {
                $(element).css(
                    "width",
                    "calc(100% - " + lightWindowManager.scrollbarWidth + "px"
                );
            });
            lightWindowManager.isFixedElementFixApplied = true;
        } else if (
            isApplyFix === false &&
            lightWindowManager.isFixedElementFixApplied === true
        ) {
            $(".js-fixed--marginleft").each(function (index, element) {
                const originalMarginLeft = parseFloat($(element).css("margin-left"));
                $(element).css(
                    "margin-left",
                    originalMarginLeft + lightWindowManager.scrollbarWidth / 2 + "px"
                );
            });
            $(".js-fixed--width100").each(function (index, element) {
                $(element).css("width", "100%");
            });
            lightWindowManager.isFixedElementFixApplied = false;
        }
    };

    LightWindow.prototype.showCover = function (
        name,
        lwtype: string,
        isUndismissable: boolean
    ): any {
        const that = this;
        lwtype = lwtype || "lightwindow";
        const $body = $("body");
        const oldBodyWidth = $body.width();
        $body.css("overflow", "hidden");
        that.scrollbarWidth = $body.width() - oldBodyWidth;
        $body.css("width", "calc(100% - " + that.scrollbarWidth + "px)");
        that.fixedElementFix(true);

        // only handle page scrolling fix on the main lightwindow
        if (!that.parentLightWindow) {
            const bodyScrollTop = $(document).scrollTop();
            $body.css("top", -bodyScrollTop).css("position", "fixed");
        }

        if (that.cover) {
            that.cover.off("click");
        }

        if (!that.cover) {
            that.cover = $(
                '<div id="cover' +
                    that.coverNumber +
                    '" class="lightwindowcover js-lightwindowcover"></div>'
            );

            that.preloaderElement = $(that.preloaderMarkup);
            that.cover.html(that.preloaderElement);
            that.cover.css("text-align", "center");

            if (that.parentLightWindow) {
                $(that.parentElement).append(that.cover);
            } else {
                //adding to body, prepend so at the top
                that.parentElement.prepend(that.cover);
            }
        }
        if (lwtype !== "alert" && isUndismissable !== true) {
            that.cover.lastMouseDownEl = undefined;
            that.cover.off("mousedown.lastEl").on("mousedown.lastEl", function (e) {
                /* prevent clicks that begin on elements that are hidden before the click is released from allowing the cover to think it was clicked
                 * e.g. custom dropdown options being clicked.
                 * then compare the mousedown event target to the click event target to make sure the user intended to click the cover
                 */
                that.cover.lastMouseDownEl = e.target;
            });
            that.cover.off("click").on("click", function (e) {
                // prevent closing on child elements
                if (
                    e.target !== this ||
                    (that.cover.lastMouseDownEl !== undefined &&
                        that.cover.lastMouseDownEl !== this)
                )
                    return;

                // disable click on self and parents
                let myCover = that;
                while (myCover) {
                    if (myCover.cover) {
                        myCover.cover.off("click");
                    }
                    myCover = myCover.parentLightWindow;
                }

                //close the parent most lightwindow
                lightWindowManager.close(lightWindowManager.currentLightWindow);
            });
        }

        $(that.cover).fadeIn(that.fadeLength);
        $(that.cover).scrollTop(0);
        return $(that.cover);
    };

    LightWindow.prototype.showPopUp = function (
        name: string,
        content: string,
        lwtype: string,
        lwclassnames: string
    ) {
        const that = this;
        let $lwContainer = $("#" + this.lightWindowId(name));
        let $cover;
        lwclassnames =
            lwclassnames && typeof lwclassnames !== "undefined" ? lwclassnames : "";
        lwtype = lwtype || "lightwindow";
        if (!$lwContainer[0] || lwtype === "alert") {
            // add the content
            $lwContainer = $(this.lwContainerTemplate);
            $lwContainer.html(content);
            $lwContainer.attr("id", this.lightWindowId(name));
            $lwContainer.css("z-index", "auto");

            if ($lwContainer.find(".js-lightwindowproperties-type").length > 0) {
                //add the type of lightwindow after the default lightwindow class
                $lwContainer[0].className = $lwContainer[0].className.replace(
                    " lightwindow ",
                    " lightwindow " +
                        $lwContainer.find(".js-lightwindowproperties-type").val() +
                        " "
                );
            }

            if ($lwContainer.find(".js-lightwindowproperties-classnames").length > 0) {
                lwclassnames +=
                    " " + $lwContainer.find(".js-lightwindowproperties-classnames").val();
            }
            $lwContainer.addClass(
                name + "panel" + (" js-" + name + "panel") + (" " + lwclassnames)
            );

            // add to the page
            $cover = $(that.cover);
            $cover.append($lwContainer);
            $lwContainer = $cover.find(".js-" + name + "panel");

            if (typeof that.initfunction === "function") {
                that.initfunction($lwContainer);
            }
        } else {
            const el = $lwContainer.detach();
            $cover = lightWindowManager.getTopCover().showCover(name);
            $cover.append(el);
            that.cover = $cover;
            $lwContainer = $cover.find(".js-" + name + "panel");
        }

        $(".js-" + name + "panel").trigger("lw-show");

        // show lightwindow
        $lwContainer.addClass("is-lwtransitioning").show();
        setTimeout(function () {
            $lwContainer.removeClass("is-lwtransitioning");
        }, 150);

        // set focus
        const scroll = $(that.cover).scrollTop();
        let $lwFocusEl = $lwContainer.find(".js-lwfocus, input, textarea, button").eq(0); //TODO: also handle dropdowns
        if (!$lwFocusEl.length) {
            $lwFocusEl = $lwContainer;
        } else {
            if (window.innerHeight <= 1024 && $lwContainer[0].offsetTop * 2 < 352) {
                if (
                    ($lwFocusEl.is("input") &&
                        $lwFocusEl.type != "button" &&
                        $lwFocusEl.type != "submit") ||
                    $lwFocusEl.type == "textarea"
                ) {
                    $lwFocusEl = $lwContainer;
                }
            }
        }
        if ($lwFocusEl === $lwContainer) {
            $lwContainer[0].tabIndex = 1;
        }
        $lwFocusEl.focus();
        $(that.cover).scrollTop(scroll);

        // vertically center
        that.verticallyCenter($lwContainer);
        $lwContainer.removeClass("lightwindow--closed");

        // close button
        if (lwtype !== "alert") {
            if ($lwContainer.find(".js-lightwindow-close")) {
                $lwContainer
                    .find(".js-lightwindow-close")
                    .unbind("click.closelw")
                    .on("click.closelw", function (e) {
                        e.preventDefault();
                        that.close(name);
                    });
            }
        }
    };

    LightWindow.prototype.verticallyCenter = function ($lwContainer) {
        let topPosition = $(this.cover).height() / 2 - $lwContainer.outerHeight(true) / 2;
        if (topPosition < 0) topPosition = 0;
        $lwContainer.css("top", topPosition);
    };

    LightWindow.prototype.getTopCover = function () {
        let topcover = lightWindowManager;
        //find the highest cover
        while (topcover.currentLightWindow && topcover.childLightWindow) {
            topcover = topcover.childLightWindow;
        }
        return topcover;
    };

    LightWindow.prototype.getTopLightWindow = function () {
        let toplw = lightWindowManager;
        //find the highest lightwindow
        while (
            toplw.childLightWindow &&
            toplw.childLightWindow.cover.find(".js-lightwindow:visible").length > 0
        ) {
            toplw = toplw.childLightWindow;
        }
        if (toplw.cover) {
            return toplw.cover.find(".js-lightwindow:visible");
        } else {
            return false;
        }
    };

    LightWindow.prototype.closeTopLightWindow = function () {
        lightWindowManager
            .getTopLightWindow()
            .find(".js-lightwindow-close")
            .first()
            .click();
    };

    LightWindow.prototype.close = function (name, shouldremove, onComplete) {
        if (typeof shouldremove !== "undefined" && shouldremove !== null) {
            console.log(
                "fn lightwindow.close shouldremove arg is deprecated, use lightwindow property shouldCache instead"
            );
        }
        function hideLightWindow() {
            if (name && $("#" + that.lightWindowId(name)).length > 0) {
                $("#" + that.lightWindowId(name)).tabIndex = "-1";
                // hide the lightwindow
                $("#" + that.lightWindowId(name))
                    .addClass("lightwindow--closed is-lwtransitioning")
                    .fadeOut(that.fadeLength, function () {
                        // close any opened tooltips in the lightwindow
                        $(that.cover).find(".tooltip-container").removeClass("show");
                        $(".js-" + name + "panel").trigger("lw-hide");
                    })
                    .removeClass("is-lwtransitioning");
            } else {
                //must have failed to load lightwindow
            }

            $(that.cover).fadeOut(230, function () {
                if (!that.parentLightWindow) {
                    const bodyTop = $("body").position().top * -1;
                    $("body")
                        .css("overflow", "")
                        .css("width", "")
                        .css("position", "")
                        .css("top", "");
                    that.fixedElementFix(false);

                    // scroll back down since we remove position fixed
                    if (bodyTop > 0) {
                        $(document).scrollTop(bodyTop);
                    }
                }

                $(that.cover).find(".js-lightwindow").hide();

                if (typeof that.parentLightWindow !== "undefined") {
                    that.parentLightWindow.childLightWindow = undefined;
                }

                that.lastFocus.focus();
                if (typeof that.callbackonclose === "function") {
                    that.callbackonclose();
                }

                const coverstoremove = [];
                let curlw = that;
                // move current and any children to our cache div
                while (curlw) {
                    var $childlw;
                    if (typeof curlw.cover !== "undefined") {
                        //push the curlw into our array for removal handling
                        coverstoremove.push(curlw);
                        $childlw = curlw.cover.find(".js-lightwindow");
                        if (curlw.shouldCache == true) {
                            //if we should cache, detach and append into cache div
                            $childlw.detach();
                            lightWindowManager.cacheEl.append($childlw);
                        }
                    }
                    curlw = curlw.childLightWindow;
                }

                if (that.coverNumber === 0) {
                    that.childLightWindow = undefined;
                }

                $.each(coverstoremove, function (index, el) {
                    //remove the lightwindow
                    $(this.cover).remove();
                    //reset the lightwindows cover and child
                    this.cover = undefined;
                    this.childLightWindow = undefined;
                    //unlink it from its previous parent
                    if (this.parentLightWindow) {
                        this.parentLightWindow.childLightWindow = undefined;
                    }
                    //clear shouldCache
                    this.shouldCache = undefined;
                });

                if (typeof onComplete === "function") {
                    onComplete();
                }
            });
        }

        function hideChildrenLightWindows(i) {
            //loop over children lightwindows and hide them starting from most nested
            const duration = 230;
            if (i >= 0) {
                const $el = hideChildren[i].cover.find(".js-lightwindow");
                $el.addClass("lightwindow--closed is-lwtransitioning")
                    .fadeOut(that.fadeLength)
                    .removeClass("is-lwtransitioning");
                $(hideChildren[i].cover).fadeOut(duration, function () {
                    hideChildrenLightWindows(i - 1);
                });
            }
            if (i === 0) {
                setTimeout(function () {
                    hideLightWindow();
                }, duration);
            }
        }

        var that = this;
        while (
            name != that.currentLightWindow &&
            typeof that.childLightWindow !== "undefined"
        ) {
            that = that.childLightWindow;
        }

        that.currentLightWindow = undefined;

        if (that.cover) {
            that.cover.off("click");
            if (that.cover.find(".js-lightwindow-close").length > 0) {
                that.cover.find(".js-lightwindow-close").unbind("click.closelw");
            }
        }

        // add children lightwindows to array
        var hideChildren = [];
        let hideChildrenItem = that.childLightWindow;
        while (hideChildrenItem) {
            hideChildren.push(hideChildrenItem);
            hideChildrenItem = hideChildrenItem.childLightWindow;
        }
        if (hideChildren.length > 0) {
            hideChildrenLightWindows(hideChildren.length - 1);
        } else {
            hideLightWindow();
        }
    };

    LightWindow.prototype.urlOverride = function (name: string): string | null {
        switch (name) {
            case "cashback":
                return "/page_fragments/lightwindow_cashback.aspx";
            case "lifecalc":
                return "/page_fragments/lightwindow_lifecalc.aspx";
            case "edityourtripdetails":
                return "/travel-insurance/edityourtripdetails.aspx";
            case "travel-voucher-terms":
                return "/page_fragments/lightwindow_travelvouchertnc.aspx";
            default:
                return null;
        }
    };

    LightWindow.prototype.isValidLightWindow = function (name) {
        return $.inArray(name, this.validLightWindows) !== -1;
    };

    LightWindow.prototype.addValidLightWindows = function (arr) {
        //check we haven't already added lightwindows to the valid list before adding them
        const that = this;
        $.each(arr, function (i, item) {
            if (that.isValidLightWindow(item) === false) {
                lightWindowManager.validLightWindows.push(item);
            }
        });
    };

    LightWindow.prototype.lightWindowId = function (name) {
        return name + "panel";
    };

    LightWindow.prototype.showAlert = function (args) {
        //message, title, callbackonclose, footer, initfunction, classnames
        args = $.extend(
            {
                message: "Sorry, an error has occurred.",
                title: undefined,
                callbackonclose: undefined,
                footer: undefined,
                initfunction: undefined,
                classnames: undefined,
                shouldCache: false
            },
            args
        );

        let alertlw = this;
        const that = this;
        const uniqueid = new Date().getTime();
        const name = "alert" + uniqueid;
        that.lastFocus = document.activeElement;
        /* if not lightWindowManager, create alert as child of child most lightwindow,
         * otherwise alert is just lightWindowManager
         */
        if (typeof that.currentLightWindow !== "undefined") {
            while (alertlw.childLightWindow) {
                // find the child most lightwindow
                alertlw = alertlw.childLightWindow;
            }
            alertlw.childLightWindow = new LightWindow(alertlw); //create new lightwindow as child
            alertlw = alertlw.childLightWindow;
        }
        alertlw.currentLightWindow = name;

        alertlw.shouldCache = args.shouldCache; //important we set this everytime so we don't inherit a previous lightwindows property
        alertlw.initfunction = args.initfunction; //important we set this everytime, whether it's a function or undefined, so we don't inherit a previous alerts function
        alertlw.callbackonclose = args.callbackonclose; //important we set this everytime, whether it's a function or undefined, so we don't inherit a previous alerts function

        alertlw.showCover(name, "alert");
        alertlw.showPopUp(
            name,
            alertlw.alertTemplate(args.message, args.title, args.footer),
            "alert",
            args.classnames
        );
        alertlw.cover.find(".js-alert-message").html(args.message);
        const closebtn = alertlw.cover.find(".js-alert-close");
        if (closebtn.length > 0) {
            closebtn.on("click.closelw", function (event) {
                alertlw.close(name);
            });
        }
    };

    if (!window.lightWindowManager) {
        // avoid double loading
        lightWindowManager = new LightWindow();
        lightWindowManager.init();
        window.lightWindowManager = lightWindowManager;
    }
})($);
