import { lightWindowManager } from "./lightwindowhelper";

var footerContactBtn = document.querySelector('.js-footer-contact');
if (footerContactBtn) {
	document.querySelector('.js-footer-contact').addEventListener('click', function (event) {
		event.preventDefault();
		lightWindowManager.open({ name: 'contact' });
	});
}
var footerSecurityBtn = document.querySelector('.js-footer-securityandprivacy');
if (footerSecurityBtn) {
	footerSecurityBtn.addEventListener('click', function (event) {
		event.preventDefault();
		lightWindowManager.open({ name: 'security' });
	});
}
var footerLegalBtn = document.querySelector('.js-footer-legalanddisclaimer');
if (footerLegalBtn) {
	footerLegalBtn.addEventListener('click', function (event) {
		event.preventDefault();
		lightWindowManager.open({ name: 'legal' });
	});
}