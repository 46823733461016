import { lightWindowManager } from "../objects/lightwindowhelper";
import * as siteHeader from "../objects/siteheader";
import * as siteFooter from "../objects/sitefooter";
import * as MobileNav from "../objects/mobilenav";

export var landing = function () {
    // legacy js files need to be explicitly referenced
    // otherwise webpack excludes them
    const mn = MobileNav;
    const sh = siteHeader;
    const sf = siteFooter;

    window.lightWindowManager = lightWindowManager;

    lightWindowManager.validLightWindows.push(
        'reviews',
        'myldtour',
        'contact',
        'cashback',
        'holidaypack'
    );

    function attachLightwindowToClick(elSelector: string, lightwindowName: string, lightwindowUrl: string) {
        var els = document.querySelectorAll(elSelector);
        for (let i = 0; i < els.length; i++) {
            els[i].addEventListener('click', function (event) {
                event.preventDefault();
                lightWindowManager.open({ name: lightwindowName, filePath: lightwindowUrl });
            });
        }
    };

    attachLightwindowToClick('.js-show-reviews', 'reviews', '/lightwindow/reviews.aspx');
    attachLightwindowToClick('.js-reasonstouseld-myld-tour', 'myldtour', '/lightwindow/myldtour.aspx');
    attachLightwindowToClick('.js-landingcontactsection-show-contact', 'contact', '/lightwindow/contact.aspx');
    attachLightwindowToClick('.js-cashback', 'cashback', '/page_fragments/lightwindow_cashback.aspx');
    attachLightwindowToClick('.js-promotion', 'promotion', '/page_fragments/lightwindow_promotion.aspx?key=FreePTWillPopup');
    attachLightwindowToClick(".js-nib-health-reward", 'promotion', '/page_fragments/lightwindow_promotion.aspx?key=NibHealthReward');

    $('.js-reasonstouseld2-morebenefitsbtn').click(function () {
        var $btnText = $('.js-reasonstouseld2-morebenefitsbtn-text');
        if ($btnText.html() == 'More benefits') {
            $btnText.html('Fewer benefits');
            $('.js-reasonstouseld2-listitem').css({ 'display': 'inline-block' });
        } else {
            $btnText.html('More benefits');
            $('.js-reasonstouseld2-listitem').css({ 'display': 'none' });
        }
    });

    $('.js-livechat').on('click', function (e) {
        window.LC_API.open_chat_window();
        e.preventDefault();
    });

    $('.js-contact-livechat').on('click', function (e) {
        window.LC_API.open_chat_window();
        e.preventDefault();
    });



    (function ratingdata() {
        var reviewData: any = {};
        var objc: any = {};
        var date = formatDate(new Date());
        $.ajax({
            url: "/service.aspx/CustomerRating_OverallCB",
            type: "POST",
            data: "",
            dataType: "json",
            contentType: "application/json;charset=utf-8",
            async: true,
            success: function (res, textStatus, xhr) {
                objc = res.d;
                reviewData.averageReview = convertFloatHelper(objc[0]);
                reviewData.numberOfReviews = objc[1];
                $('.js-customerfeedback-overallrating-loading').remove();
                $('.js-customerfeedback-overallrating-rating').html('<span class="customerfeedback-overallrating-value">' + reviewData.averageReview + '</span><span class="customerfeedback-overallrating-divider">/</span>5');
                $('.js-customerfeedback-overallrating-date').html(date);
                ratingstars(5);
                addStructuredData(reviewData);
            },
            error: function (xhr, status, e) {
                console.log('There was an error getting the customer rating data.');
            }
		});

        function addStructuredData(reviewData) {
            var el = document.createElement('script');
            el.type = 'application/ld+json';
            el["nonce"] = "<%= HttpContext.Current.Response.Cookies.Get('scriptNonce').Value %>";
            var ratingValue = (reviewData.averageReview != null) ? reviewData.averageReview : 4.6;
            var reviewCount = (reviewData.numberOfReviews != null) ? reviewData.numberOfReviews : 9000;
            var message = {
                "@context": "http://schema.org",
                "@type": "Organization",
                "review": [
                    {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5"
                        },
                        "author": {
                            "@type": "Person",
                            "name": "Dion Ahern"
                        },
                        "reviewBody": "Awesome - made the process so much easier."
                    },
                    {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5"
                        },
                        "author": {
                            "@type": "Person",
                            "name": "David Page"
                        },
                        "reviewBody": "Fantastic. So easy, so immediate, so responsive.Thank you."
                    },
                    {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5"
                        },
                        "author": {
                            "@type": "Person",
                            "name": "Erica Willoughby"
                        },
                        "reviewBody": "Easy to use and very quick to respond."
                    },
                    {
                        "@type": "Review",
                        "reviewRating": {
                            "@type": "Rating",
                            "ratingValue": "5"
                        },
                        "author": {
                            "@type": "Person",
                            "name": "Caryn Peden"
                        },
                        "reviewBody": "Very friendly service. Timely updates. Thanks so much."
                    }
                ],
                "aggregateRating": {
                    "@type": "AggregateRating",
                    "reviewCount": JSON.parse(reviewCount),
                    "ratingValue": JSON.parse(ratingValue)
                }
            };

            el.text = JSON.stringify(message);
            document.querySelector('body').appendChild(el);
        }

        function ratingstars(numstars) {
            var stars = '',
                startype = '',
                averagereview = reviewData.averageReview;
            for (let i = 0; i < numstars; i++) {
                startype = averagereview - (i + 1) > 0 ? 'icon-star--full' : 'icon-star--' + averagereview.substring(2);
                stars += '<span class="icon-star ' + startype + '"></span>';
                if (i === numstars - 1) {
                    $('.js-customerfeedback-overallrating-stars').html(stars);
                }
            }
        }
        /*
        *	Returns a date in d-MMM-yyyy format
        */
        function formatDate(date) {
            var months = new Array("Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec");
            return date.getDate() + ' ' + months[date.getMonth()] + ' ' + date.getFullYear();
        }
        function convertFloatHelper(value) {
            return parseFloat(value.toString()).toFixed(1);
        }
    }());

    window.mobilenav && window.mobilenav.init();
};

landing();