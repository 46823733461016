import { lightWindowManager } from "./lightwindowhelper";

interface IOptions {
    open?: boolean;
    pushPage?: boolean;
    scrollTopOnShow?: boolean;
    allowPageScroll?: boolean;
    showOnResizeToDesk?: boolean;
    hideOnResizeToDesk?: boolean;
    showOnResizeToMobile?: boolean;
    hideOnResizeToMobile?: boolean;
    hideOnClickOutside?: boolean;
    addRightMargin?: boolean;
}

interface IMobileNav extends IOptions {
    // options
    menu: JQuery;
    menubtn: JQuery;
    html: JQuery;
    body: JQuery;
    pagewrapper: JQuery;
    menubtnbreakpoint: number;
    menuminwidth: number;
    menumaxwidth: number;
    menuwidth: number;
    sneakpeek: number;

    pagex: number;
    startx: null;
    opening: boolean;

    // functions
    init: (opts?: IOptions) => void;
    displaynav: () => void;
    show: () => void;
    hide: () => void;
    resize: () => void;
    sizemenu: () => void;
    togglesubmenu: () => void;

    // Optional functions
    startShowingCallback?: () => void;
    startHidingCallback?: () => void;
}

interface ILiveChatAPI {
    open_chat_window?: () => void;
    on_after_load?: () => void;
}

export let mobilenav: IMobileNav;

declare global {
    // eslint-disable-next-line
    interface Window {
        mobilenav: IMobileNav;
        LC_API: ILiveChatAPI;
    }
}

(function () {
    mobilenav = {
        menu: $(".js-mobilenav"),
        menubtn: $(".js-siteheader-menubtn"),
        html: $("html"),
        body: $("body"),
        pagewrapper: $(".js-pagewrapper"),
        menubtnbreakpoint: 768,
        menuminwidth: 240,
        menumaxwidth: 360,
        menuwidth: 240,
        sneakpeek: 80,
        open: false,
        pagex: $(".js-pagewrapper").position().left,
        startx: null,
        opening: false,
        allowPageScroll: false,
        scrollTopOnShow: true,
        pushPage: true,
        showOnResizeToDesk: false,
        hideOnResizeToDesk: true,
        showOnResizeToMobile: false,
        hideOnResizeToMobile: false,
        hideOnClickOutside: true,
        addRightMargin: false,
        startShowingCallback: null,
        startHidingCallback: null,

        init: function (opts) {
            //use options
            if (opts) {
                if (opts.open !== undefined) {
                    mobilenav.open = opts.open;
                }
                if (opts.pushPage !== undefined) {
                    mobilenav.pushPage = opts.pushPage;
                }
                if (opts.allowPageScroll !== undefined) {
                    mobilenav.allowPageScroll = opts.allowPageScroll;
                }
                if (opts.scrollTopOnShow !== undefined) {
                    mobilenav.scrollTopOnShow = opts.scrollTopOnShow;
                }
                if (opts.showOnResizeToDesk !== undefined) {
                    mobilenav.showOnResizeToDesk = opts.showOnResizeToDesk;
                }
                if (opts.hideOnResizeToDesk !== undefined) {
                    mobilenav.hideOnResizeToDesk = opts.hideOnResizeToDesk;
                }
                if (opts.showOnResizeToDesk !== undefined) {
                    mobilenav.showOnResizeToDesk = opts.showOnResizeToDesk;
                }
                if (opts.hideOnResizeToMobile !== undefined) {
                    mobilenav.hideOnResizeToMobile = opts.hideOnResizeToMobile;
                }
                if (opts.hideOnClickOutside !== undefined) {
                    mobilenav.hideOnClickOutside = opts.hideOnClickOutside;
                }
                if (opts.addRightMargin !== undefined) {
                    mobilenav.addRightMargin = opts.addRightMargin;
                }
            }

            if (mobilenav.menu.length > 0) {
                //only run if mobilenav exists on the page
                mobilenav.sizemenu();
                mobilenav.menubtn.on("click", mobilenav.show);
                mobilenav.menu.on(
                    "click",
                    ".js-mobilenav-submenuheading",
                    mobilenav.togglesubmenu
                );
                $(window).on("debouncedresize", mobilenav.resize);
            }

            if (mobilenav.open) {
                mobilenav.displaynav();
            }

            const mobileContactBtn = document.querySelector(".js-mobilenav-contact");
            if (mobileContactBtn) {
                mobileContactBtn.addEventListener("click", function (e) {
                    e.preventDefault();
                    lightWindowManager.open({ name: "contact" });
                });
            }

            const requestCallBtn = document.querySelector(".js-mobilenav-requestcall");
            if (requestCallBtn) {
                requestCallBtn.addEventListener("click", function (e) {
                    e.preventDefault();
                    lightWindowManager.open({ name: "requestcallback" });
                });
            }

            const openLiveChatBtn = document.querySelector(".js-mobilenav-openlivechat");
            if (openLiveChatBtn) {
                openLiveChatBtn.addEventListener("click", function (e) {
                    e.preventDefault();
                    if (window.LC_API) {
                        window.LC_API.open_chat_window();
                        $("#livechat-full").show();
                    } else {
                        const LC_API: ILiveChatAPI = window.LC_API || {};
                        // eslint-disable-next-line
                        LC_API.on_after_load = function () {
                            if (typeof(LC_API.open_chat_window) === "function") {
                                LC_API.open_chat_window();
                            }
                        };
                    }
                });
            }

            const evt = new CustomEvent("mobilenavloaded");
            window.dispatchEvent(evt);
        },
        displaynav: function () {
            mobilenav.html.addClass("nav-is-open");
            mobilenav.open = true;
            if (mobilenav.allowPageScroll === false) {
                mobilenav.html.addClass("is-mobilenavscrollonly");
                mobilenav.body.height($(window).height());
            }
            mobilenav.menu.css({ width: mobilenav.menuwidth });
            const animateProperties = {
                left: mobilenav.menuwidth
            };
            if (mobilenav.pushPage === true) {
                if (mobilenav.addRightMargin === true) {
                    animateProperties["margin-right"] = mobilenav.menuwidth;
                }
                mobilenav.pagewrapper.animate(animateProperties, 300, function () {
                    mobilenav.menubtn.off("click").on("click", mobilenav.hide);
                    if (mobilenav.hideOnClickOutside === true) {
                        mobilenav.pagewrapper
                            .off("click.mobilenavdisplay")
                            .on("click.mobilenavdisplay", mobilenav.hide);
                    }
                });
            } else {
                mobilenav.menubtn.off("click").on("click", mobilenav.hide);
            }
        },
        show: function () {
            if (mobilenav.allowPageScroll === false) {
                mobilenav.body.height($(window).height());
            }

            if (typeof mobilenav.startShowingCallback === "function") {
                mobilenav.startShowingCallback();
            }

            let completeCalled = false;
            mobilenav.menubtn.off("click");
            if (mobilenav.scrollTopOnShow === true) {
                if ($("html").scrollTop() === 0 && $("body").scrollTop() === 0) {
                    mobilenav.displaynav();
                } else {
                    $("html, body").animate(
                        { scrollTop: "0px" },
                        {
                            duration: 300,
                            complete: function () {
                                if (!completeCalled) {
                                    completeCalled = true;
                                    mobilenav.displaynav();
                                }
                            }
                        }
                    );
                }
            } else {
                mobilenav.displaynav();
            }
        },
        hide: function () {
            if (typeof mobilenav.startHidingCallback === "function") {
                mobilenav.startHidingCallback();
            }

            const animateProperties = {
                left: "0px"
            };
            mobilenav.menubtn.off("click", mobilenav.hide);
            mobilenav.pagewrapper.off("click.mobilenavdisplay");
            if (mobilenav.addRightMargin === true) {
                animateProperties["margin-right"] = "0px";
            }
            mobilenav.pagewrapper.animate(animateProperties, 300, function () {
                mobilenav.html.removeClass("nav-is-open");
                mobilenav.html.removeClass("is-openingmobilenav");
                mobilenav.html.removeClass("is-mobilenavscrollonly");
                mobilenav.open = false;
                mobilenav.opening = false;
                mobilenav.body.height("");
                mobilenav.menubtn.on("click", mobilenav.show);
            });
        },
        resize: function () {
            const smallwindow = $(window).width() < mobilenav.menubtnbreakpoint;
            if (smallwindow) {
                mobilenav.sizemenu();
                if (mobilenav.showOnResizeToMobile === true) {
                    mobilenav.show();
                } else if (mobilenav.hideOnResizeToMobile === true) {
                    mobilenav.hide();
                }
            } else {
                if (mobilenav.showOnResizeToDesk === true) {
                    mobilenav.show();
                } else if (mobilenav.hideOnResizeToDesk === true) {
                    mobilenav.hide();
                }
            }
        },
        sizemenu: function () {
            const windowwidth = $(window).width();
            const fillwidth = windowwidth - mobilenav.sneakpeek;
            const min = mobilenav.menuminwidth;
            const max = mobilenav.menumaxwidth;

            if (windowwidth > min + mobilenav.sneakpeek) {
                if (fillwidth < max) {
                    mobilenav.menuwidth = fillwidth;
                } else {
                    mobilenav.menuwidth = max;
                }
            } else {
                mobilenav.menuwidth = min;
            }
            if (mobilenav.open) {
                mobilenav.menu.css({ width: mobilenav.menuwidth });
                if (mobilenav.pushPage === true) {
                    mobilenav.pagewrapper.addClass('nav-is-open');
                    if (mobilenav.addRightMargin === true) {
                        mobilenav.pagewrapper.css({
                            "margin-right": mobilenav.menuwidth
                        });
                    }
                }
            }
        },
        togglesubmenu: function () {
            const submenu = $(this).closest(".js-mobilenav-submenu");
            const submenulist = submenu.find(".js-mobilenav-submenu-list");
            if (!submenu.hasClass("is-open")) {
                submenulist.stop();
                submenulist.slideDown(100);
                submenu.addClass("is-open");
            } else {
                submenulist.stop();
                submenulist.slideUp(100);
                submenu.removeClass("is-open");
            }
        }
    };
    window.mobilenav = mobilenav;
})();
